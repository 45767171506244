import React from 'react'


import { FaClock } from "react-icons/fa6";

const Cards = () => {
  return (
    <div className='container mx-auto mt-[8%] mm:mb-[15%] lg:mb-[6%]'>
      <div className='flex flex-wrap mm:flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 justify-center items-center text-white font-Marri'>
        
        <div className='bg-[#ED6DB3] p-6 w-full md:w-1/4 lg:w-1/5 h-[150px] md:text-base gx:text-xl flex flex-col justify-center items-center rounded-xl'>
          <div className='uppercase flex justify-center items-center gap-2'>
            <FaClock />
            Horário
          </div>
          <div className='text-center'>
            Segunda - Segunda <br />
            08:00 às 21:00
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cards
