import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="py-[3%] mm:px-4 lg:px-[13%]">
      <div className="container mm:text-center lg:text-left mx-auto bg-[#ED6DB3] text-white p-8 rounded-lg shadow-lg">
        <h1 className="text-4xl font-bold mb-8 text-center text-texto-titulo">Política de Privacidade</h1>
        <p className="text-lg mb-4">
          Bem-vindo à nossa Política de Privacidade. A sua privacidade é importante para nós. Esta política descreve como coletamos, usamos, protegemos e compartilhamos suas informações pessoais.
        </p>
        <h2 className="text-2xl font-bold mb-4">Informações da Empresa</h2>
        <p className="text-lg mb-4">
          Esta política é mantida por Donuts Belizario comercios de doces Ltda, inscrita sob o CNPJ: 39.889.075/0001-71
          . Nós estamos comprometidos em proteger a sua privacidade e garantir a segurança dos seus dados.
        </p>
        <h2 className="text-2xl font-bold mb-4">Coleta de Informações</h2>
        <p className="text-lg mb-4">
          Coletamos informações pessoais que você nos fornece diretamente, como quando você cria uma conta, se inscreve em nosso boletim informativo ou entra em contato conosco. As informações podem incluir seu nome, endereço de e-mail, número de telefone e outras informações de contato.
        </p>
        <p className="text-lg mb-4">
          Também podemos coletar informações automaticamente quando você usa nossos serviços, como seu endereço IP, tipo de navegador, sistema operacional e dados de navegação.
        </p>
        <h2 className="text-2xl font-bold mb-4">Uso das Informações</h2>
        <p className="text-lg mb-4">
          Usamos suas informações para fornecer, manter e melhorar nossos serviços, personalizar sua experiência, enviar comunicados, responder a consultas e fornecer suporte ao cliente.
        </p>
        <h2 className="text-2xl font-bold mb-4">Proteção de Informações</h2>
        <p className="text-lg mb-4">
          Implementamos medidas de segurança para proteger suas informações pessoais contra acesso não autorizado, alteração, divulgação ou destruição. No entanto, nenhum método de transmissão pela internet ou armazenamento eletrônico é completamente seguro.
        </p>
        <h2 className="text-2xl font-bold mb-4">Compartilhamento de Informações</h2>
        <p className="text-lg mb-4">
          Não compartilhamos suas informações pessoais com terceiros, exceto conforme necessário para fornecer nossos serviços, cumprir obrigações legais, proteger nossos direitos ou com seu consentimento.
        </p>
        <h2 className="text-2xl font-bold mb-4">Seus Direitos</h2>
        <p className="text-lg mb-4">
          Você tem o direito de acessar, corrigir, atualizar e excluir suas informações pessoais. Se desejar exercer esses direitos, entre em contato conosco.
        </p>
        <h2 className="text-2xl font-bold mb-4">Alterações a Esta Política</h2>
        <p className="text-lg mb-4">
          Podemos atualizar esta Política de Privacidade periodicamente. Notificaremos você sobre quaisquer mudanças, publicando a nova política em nosso site. Recomendamos que você reveja esta política regularmente.
        </p>
        <p className="text-lg mb-4">
          Se tiver alguma dúvida ou preocupação sobre nossa Política de Privacidade, entre em contato conosco pelo e-mail contato@pmocsmart.com.br.

        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
