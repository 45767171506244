import React from 'react'
import Header from '../components/Header'
import PrivacyPolicy from '../components/PrivacyPolicy'
import Footer from '../components/Footer'

const Privacidade = () => {
  return (
    <div className='bg-[#8B336A]'>
        <Header/>
        <PrivacyPolicy/>
        <Footer/>
    </div>
  )
}

export default Privacidade