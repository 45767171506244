import React from 'react';
import img1 from "../assets/slider-img.png"

const Hero = () => {
  return (
    <div className="flex flex-col   text-white md:flex-row items-center justify-between pt-[4%]  ">
      <div className='container flex mm:flex-col lg:flex-row mm:px-4 lg:px-[13%]'>
          <div className="md:w-1/2 text-center flex flex-col justify-center md:text-left">
            <div className="mm:text-3xl lg:text-5xl font-bold mb-4 uppercase">Donuts Gourmet <br /> Um Toque de Doçura em Cada Mordida</div>
            <div className="text-xl mb-6">Cada donut que você experimentar em nossa loja é feito com cuidado e dedicação. Oferecemos uma seleção exclusiva de sabores que são assados diariamente para garantir frescor e sabor incomparáveis. Venha experimentar nossos donuts e sinta a diferença!</div>
            <button className="bg-[#fccb91] lg:w-[50%] text-white text-lg font-medium py-2 px-4 rounded transition-all duration-300 ease-in-out hover:bg-[#FCCB91]">
              Entre em contato conosco
            </button>
          </div>
          <div className="md:w-1/2 mt-8 md:mt-0 flex justify-end">
            <img src={img1} alt="" className="lg:w-[80%] mm:w-[100%] h-auto rounded animate-bounce" />
          </div>
      </div>
    </div>
  );
};

export default Hero;
